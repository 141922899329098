export default [{
    title: 'Visitas',
    icon: 'MapPinIcon',
    children: [
        {
            title: 'Lista',
            route: 'visits-list',
            action: 'manage',
		    resource: 'visits'
        }
    ]
}];