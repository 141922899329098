import home from './home';
import bidders from './bidders';
import tenders from './tenders';
import visits from './visits';

export default [
    ...home,
    ...bidders,
    ...tenders,
	...visits
];