export default [{
    title: 'Postores',
    icon: 'BriefcaseIcon',
    children: [
        {
            title: 'Lista',
            route: 'bidders-list',
            action: 'read',
		    resource: 'bidders'
        }, 
        {
            title: 'Agregar',
            route: 'bidders-create',
            action: 'create',
		    resource: 'bidders'
        }
    ]
}];